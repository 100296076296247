"use client";

import { useEffect, useState } from "react";
import { useLists } from "~/providers/ListsProvider";
import MobilePage from "./mobileScreen/page"; // Assuming MobilePage is imported from this path
import { redirect } from "next/navigation";
import { api } from "~/server/trpc/react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

export default function HomePage() {
  const { lists, isLoading } = useLists();
  const { activeTeamAccount } = useTeamAccount();

  const { data: repoContactsCount = 0, isLoading: isLoadingRepoContactsCount } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: activeTeamAccount.id,
      type: "repo",
    },
      {
        enabled: !!activeTeamAccount.id
      }
    );

  const { data: userContactsCount = 0, isLoading: isLoadingUserContactsCount } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: activeTeamAccount.id,
      type: "user",
    },
      {
        enabled: !!activeTeamAccount.id && repoContactsCount === 0
      }
    );

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  if (isMobile) {
    return <MobilePage />;
  }

  if (isLoading || isLoadingRepoContactsCount || isLoadingUserContactsCount) {
    return null
  }

  if (lists.length > 0) {
    if (repoContactsCount > 0) {
      return redirect(`/overview/teams`)
    } else if (userContactsCount > 0) {
      return redirect(`/overview/contributors`)
    } else {
      return redirect(`/lists/${lists[0].id}/teams`)
    }
  } else {
    return redirect(`/lists/new`)
  }


}
